import { publicIp } from "public-ip"
import CryptoJS from "crypto-js"
import {
  Address,
  Card,
  EnvironmentType,
  Item,
  Itinerary,
  MddField,
  OrderPayment,
  Passenger,
  ResponseIpIfy,
  WorldpayPaymentRequest,
  ViancaPayPaymentRequest,
  TokenUS,
  PaymentMethodsRequest
} from "../../models/payment"
import { makeGetRequest } from "../HttpAdapterAxios"
import {
  postTokenPayU,
  postViancaPayAuthorization,
  postWorldpayAuthorization,
  postViancaPayPayment,
  postViancaPayPaymentUs,
  postPaymentMethods
} from "../../services/requestViancaPay"
import {
  DictionariesFlight,
  PaymentRecord,
  RetrievesAnOrder,
  Service,
  Traveler,
} from "../../models/retrievesAnOrder"

export const getShopperID = async () => import.meta.env.VITE_REACT_APP_WORLDPAY_SHOPPERID!;

export const getTokenReference = async () => import.meta.env.VITE_REACT_APP_WORLDPAY_TOKEN_REFERENCE!;

export const getChannelAVCOM = async () => import.meta.env.VITE_REACT_APP_CHANNEL_AVCOM!;

export const getChannelPaymentMethod = async () => import.meta.env.VITE_REACT_APP_CHANNEL_PAYMENT_METHOD!;

export const getTokenPayU = async (card: Card, lang: string) => {
  return await postTokenPayU({
    language: lang,
    command: "CREATE_TOKEN",
    creditCardToken: {
      payerId: "1",
      name: `${card.firstName} ${card.surname}`,
      identificationNumber: card.documentNumber,
      paymentMethod: card.cardFranchise,
      number: card.cardNumber,
      expirationDate: card.cardExpirationDate,
    },
  })
}

export const getEnvironmentType = (): EnvironmentType => {
  let fullPath = window.location.href
  let environment = "test"
  if (fullPath.includes("servicing.avianca.com")) {
    environment = "live"
  }
  return { merchantID: "Avianca_web", environment }
}

export const getDeviceSessionId = (): string => {
  return new Date().getTime().toString()
}

export const getOrganizationId = (environmentType: EnvironmentType) => {
  let org_id = "1snn5n9w"
  if (environmentType.environment.toLowerCase() === "live") {
    org_id = "k8vif92e"
  }
  return org_id
}

export const handleAddCyberSourceProfile = (
  org_id: string,
  sessionID: string,
) => {
  let script = document.createElement("script")
  script.type = "text/javascript"
  script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${org_id}&session_id=avianca_web${sessionID}`
  document.getElementsByTagName("head")[0].appendChild(script)
  let noscript = document.createElement("noscript")
  let iframe = document.createElement("iframe")
  iframe.setAttribute(
    "style",
    "width: 100px height: 100px border: 0 position:absolute top: -5000px",
  )
  iframe.setAttribute(
    "src",
    `https://h.online-metrix.net/fp/tags?org_id=${org_id}&session_id=avianca_web${sessionID}`,
  )
  noscript.appendChild(iframe)
  document.body.appendChild(noscript)
}

export const getAESEncrypt = (): string => {
  const viancaPaySecret = import.meta.env.VITE_REACT_APP_VIANCAPAY_SECRET
  const viancaPayIv = import.meta.env.VITE_REACT_APP_VIANCAPAY_IVCODE
  const key = CryptoJS.enc.Utf8.parse(viancaPaySecret)
  const iv = CryptoJS.enc.Utf8.parse(viancaPayIv)
  const dateNow = new Date().toISOString().split(".")[0]
  let textEncrypted = `client_id=${import.meta.env.VITE_REACT_APP_VIANCAPAY_CLIENTID}&client_secret=${import.meta.env.VITE_REACT_APP_VIANCAPAY_CLIENTSECRET}&resource=api://${import.meta.env.VITE_REACT_APP_VIANCAPAY_RESOURCE}&date=${dateNow}`
  let encryptedCP = CryptoJS.AES.encrypt(textEncrypted, key, { iv: iv })
  return encryptedCP.ciphertext.toString(CryptoJS.enc.Hex)
}

export const getIpNavigation = async () => {
  try {
    const ipAddress = await makeGetRequest<ResponseIpIfy>(
      "https://api.ipify.org?format=json",
    )
    return ipAddress ? ipAddress.ip : await publicIp()
  } catch (error) {
    return await publicIp()
  }
}

export const getViancaPayAuthorization = async (authorizationViancaPay: string) => {
  return await postViancaPayAuthorization({
    value: authorizationViancaPay,
  })
}

const handleExpirationDateFormat = (cardExpiration: string) => {
  const sliceDate = cardExpiration.split("/")
  return new Date(parseInt(sliceDate[0]), parseInt(sliceDate[1]) - 1, 1).toISOString().slice(0, 19)
}

const handleExpirationDateFormatUS = (cardExpiration: string) => {
  const sliceDate = cardExpiration.split("/")
  return sliceDate[1] + sliceDate[0]
}

const getGuidCode = (): string => {
  return `${[1e7]}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}

const getPassengers = (travelers: Traveler[], card: Card): Passenger[] => {
  return travelers.map((traveler: Traveler, key: number) => {
    const { names } = traveler
    const address: Address = {
      country: card.country,
      city: card.city,
      street: card.address,
    }
    return {
      id: key,
      travelerId: null,
      country: card.country,
      level: "",
      firstName: names[0].firstName,
      lastName: names[0].lastName,
      documentType: card.documentTypeCod,
      documentNumber: card.documentNumber,
      email: card.email,
      officePhoneNumber: "",
      homePhoneNumber: `+${card.countryCode} ${card.phoneNumber}`,
      mobilePhoneNumber: "",
      address,
    }
  })
}

const getItinerary = (flights: {
  [key: string]: DictionariesFlight
}): Itinerary[] => {
  const orderFlights = Object.entries(flights).toSorted()
  return orderFlights.map(
    (flightItem: [string, DictionariesFlight]): Itinerary => {
      return {
        departureDate: `${flightItem[1].departure.dateTime.slice(0, -6)}`,
        arrivalDate: `${flightItem[1].arrival.dateTime.slice(0, -6)}`,
        flightNumber: `${flightItem[1].marketingFlightNumber}`,
        origin: `${flightItem[1].departure.locationCode}`,
        destination: `${flightItem[1].arrival.locationCode}`,
        travelClass: `${flightItem[1].meals.bookingClass}`,
      }
    },
  )
}

const getTripType = (flights: {
  [key: string]: DictionariesFlight
}): string => {
  const sortFlight = Object.entries(flights).toSorted()
  const lengthFlight: number = sortFlight.length
  const departureFlight: DictionariesFlight = sortFlight[0][1]
  const arrivalFlight: DictionariesFlight = sortFlight[lengthFlight - 1][1]
  if (
    departureFlight.departure.locationCode ===
    arrivalFlight.arrival.locationCode
  ) {
    return "Round Trip"
  }
  return "One way"
}

const getMerchantReferenceCode = (orderId: string): string => {
  const isoDateString = new Date().toISOString()
  return `${orderId}_${isoDateString
    .split("-")
    .join("")
    .split("T")
    .join("_")
    .split(":")
    .join("")
    .split("Z")
    .join("")}`
}

const getDepartureDateTime = (flights: {
  [key: string]: DictionariesFlight
}): string => {
  const sortFlight = Object.entries(flights).toSorted()
  const departureFlight: DictionariesFlight = sortFlight[0][1]
  const datetime = departureFlight.departure.dateTime
  return handleFormatDateCybersource(datetime)
}

const handleFormatDateCybersource = (datetime: string): string => {
  let formated_date = new Date(datetime)
  let hours: number | string = formated_date.getHours()
  let minutes: number | string = formated_date.getMinutes()
  let ampm = hours > 12 ? "pm" : "am"
  if (hours >= 0 && hours <= 9) hours = "0" + hours
  if (minutes >= 0 && minutes <= 9) minutes = "0" + minutes
  let date = JSON.stringify(formated_date)
  date = date.split("T").join(" ")
  let dateOk = date.substring(1, 11)
  return `${dateOk} ${hours}:${minutes} ${ampm} GMT-5:00`
}

const getCompleteRoute = (flights: {
  [key: string]: DictionariesFlight
}): string => {
  const sortFlight = Object.entries(flights).toSorted()
  const departureFlight: DictionariesFlight = sortFlight[0][1]
  const arrivalFlight: DictionariesFlight = sortFlight[sortFlight.length - 1][1]
  if (sortFlight.length === 1) {
    let newLflight: any = sortFlight[0][1]
    return `${newLflight.departure.locationCode}-${newLflight.arrival.locationCode}`
  }
  return `${departureFlight.departure.locationCode}-${departureFlight.arrival.locationCode}:${arrivalFlight.departure.locationCode}-${arrivalFlight.arrival.locationCode}`
}

const getItems = (orderPayment: OrderPayment) => {
  const countryCode = orderPayment.retrievesAnOrder.data?.creationPointOfSale.countryCode!;
  const servicesToPay = getServicesToPay(orderPayment.retrievesAnOrder)
  const servicesToPayCABG = getServicesToPayForType(servicesToPay!, "CABG")
  return servicesToPayCABG.map((service: Service, key: number): Item => {

    let totalAmount = service.prices ? service.prices.totalPrices[0].total.value : 0
    let formatUSTotalAmount = `${Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: 2 }).format(totalAmount / 100)}`

    return {
      ...(countryCode === 'CO' ? { unitPrice: `${totalAmount}` } : { unitPrice: formatUSTotalAmount }),
      passengerFirstName: null,
      passengerLastName: null,
      passengerID: null,
      passengerPhone: null,
      passengerEmail: null,
      passengerType: null,
      id: `${key + 1}`,
      passengerStatus: null,
      quantity: service.quantity ? `${service.quantity}` : null,
      productCode: service.descriptions[0].content,
      productName: service.descriptions[1].content,
      productSKU: service.descriptions[0].content,
      ...(countryCode === 'CO' ? { totalAmount: `${totalAmount}` } : { totalAmount: formatUSTotalAmount }),
    }
  })
}

export const getServicesToPay = (
  retrievesAnOrder: RetrievesAnOrder,
): Service[] | undefined => {
  const { services, paymentRecords } = retrievesAnOrder.data!
  if (services !== undefined && paymentRecords !== undefined) {
    return services?.filter((service: Service) => {
      return !paymentRecords.some((paymentRecord: PaymentRecord) => {
        return (
          paymentRecord.serviceItemIds &&
          paymentRecord.serviceItemIds[0] === service.id
        )
      })
    })
  }
}

export const getServicesToPayForType = (services: Service[], type: string) => {
  try {
    return services.filter((service) => {
      return service.descriptions[0].content === type
    })
  } catch (error) {
    return []
  }
}

export const getServicesToPayForOffice = (services: Service[]) => {
  try {
    return services.filter((service) => {
      return service.creation.office
    })
  } catch (error) {
    return []
  }
}

const getServiceForId = (retrievesAnOrder: RetrievesAnOrder): string[] => {
  const servicesToPay = getServicesToPay(retrievesAnOrder)
  const servicesToPayForType = getServicesToPayForType(servicesToPay!, "CABG")
  return servicesToPayForType.map((service: Service) => {
    return service.id
  })
}

export const getGrandTotalAmount = (
  retrievesAnOrder: RetrievesAnOrder,
): number => {
  const servicesToPay = getServicesToPay(retrievesAnOrder)
  const servicesToPayForType = getServicesToPayForType(servicesToPay!, "CABG")
  const pricePerService = servicesToPayForType.map((service: Service) => {
    return service.prices.totalPrices[0].total.value
  })
  return pricePerService.reduce((accumlator, currentValue) => {
    return accumlator + currentValue
  }, 0)
}

export const getPayReject = (retrievesAnOrder: RetrievesAnOrder) => {
  const servicesToPay = getServicesToPay(retrievesAnOrder)
  const servicesToPayForType = getServicesToPayForType(servicesToPay!, "CABG")
  return servicesToPayForType
}

const cleanNumber = (number: string): string => {
  return number.replace(/\D/g, "")
}

const cybersourceMddField33 = (retrievesAnOrder: RetrievesAnOrder) => {
  let { flight } = retrievesAnOrder.dictionaries!
  let response: number = 0
  for (const valueFlight in flight) {
    response += flight[valueFlight].duration
  }
  return `${response}`
}

const cybersourceMddField41 = (retrievesAnOrder: RetrievesAnOrder) => {
  const { flight } = retrievesAnOrder.dictionaries!
  const sortFlight = Object.entries(flight).toSorted()
  const dateDeparture = new Date(sortFlight[0][1].departure.dateTime)
  return dateDeparture.toLocaleString("es-ES", { weekday: "long" })
}

const cybersourceMddField42 = (retrievesAnOrder: RetrievesAnOrder) => {
  const { flight } = retrievesAnOrder.dictionaries!
  const sortFlight = Object.entries(flight).toSorted()
  const dateDeparture = new Date(sortFlight[0][1].departure.dateTime)
  const currentdate: Date = new Date(dateDeparture)
  const oneJan = new Date(currentdate.getFullYear(), 0, 1)
  const numberOfDays = Math.floor(
    (Number(currentdate) - Number(oneJan)) / (24 * 60 * 60 * 1000),
  )
  return `${Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7)}`
}

const cybersourceMddField44 = (retrievesAnOrder: RetrievesAnOrder) => {
  return `${retrievesAnOrder.data?.travelers.length}`
}

const cybersourceMddField45 = (retrievesAnOrder: RetrievesAnOrder) => {
  const { flight } = retrievesAnOrder.dictionaries!
  const sortFlight = Object.entries(flight).toSorted()
  const dateDeparture = sortFlight[0][1].duration
  return getSecondsToString(dateDeparture, 1)
}

const getSecondsToString = (seconds: number, format: number = 1) => {
  let hourBase = Math.floor(seconds / 3600)
  let hour: string | number = ""
  let timeReturned = ""
  hour = hourBase < 10 ? "0" + hourBase : hourBase
  if (format === 3) {
    hour = hourBase
  }
  let minute: string | number = Math.floor((seconds / 60) % 60)
  minute = minute < 10 ? "0" + minute : minute
  if (format === 2) {
    return hour + ":" + minute
  }
  timeReturned = hour + "h " + minute + "m"
  if (format === 3) {
    timeReturned = hour + "h " + minute + " min"
  }
  return timeReturned
}

const cybersourceMddField46And47 = (
  retrievesAnOrder: RetrievesAnOrder,
  typeFlight: string,
) => {
  const { flight } = retrievesAnOrder.dictionaries!
  const sortFlight = Object.entries(flight).toSorted()
  if (typeFlight === "departure") {
    return sortFlight[0][1].departure.locationCode
  }
  return sortFlight[0][1].arrival.locationCode
}

const getFormatDateCybersource = (
  datetime: any,
  format2: boolean = false,
): string => {
  let formated_date = new Date(datetime)
  let hours: number | string = formated_date.getHours()
  let minutes: number | string = formated_date.getMinutes()
  let ampm = hours > 12 ? "pm" : "am"
  if (hours >= 0 && hours <= 9) {
    hours = "0" + hours
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes
  }
  let date = JSON.stringify(formated_date)
  date = date.split("T").join(" ")
  let dateOk = date.substring(1, 11)
  if (format2) {
    const year = dateOk.substring(0, 4)
    const month = dateOk.substring(5, 7)
    const day = dateOk.substring(8, 10)
    return `${day}/${month}/${year} ${hours}:${minutes}${ampm} GMT-5:00`
  }
  return `${dateOk} ${hours}:${minutes} ${ampm} GMT-5:00`
}

const cybersourceMddField55 = (flight: {
  [key: string]: DictionariesFlight
}) => {
  let sortFlight = Object.entries(flight).toSorted()
  let arrivalDateTimeStart: any = sortFlight[0][1]
  arrivalDateTimeStart = arrivalDateTimeStart.arrival.dateTime
  let diff: string | number = "0"
  if (sortFlight.length > 1) {
    let departureDateTimeEnd: any = sortFlight[sortFlight.length - 1][1]
    departureDateTimeEnd = departureDateTimeEnd.departure.dateTime
    arrivalDateTimeStart = new Date(arrivalDateTimeStart)
    departureDateTimeEnd = new Date(departureDateTimeEnd)
    diff = departureDateTimeEnd - arrivalDateTimeStart
    diff = diff / (1000 * 60 * 60 * 24)
    diff = diff.toString()
    diff = diff.substring(0, 16)
  }
  return diff
}

const getMddField = (
  retrievesAnOrder: RetrievesAnOrder,
  card: Card,
): MddField[] => {
  const { flight } = retrievesAnOrder.dictionaries!
  const sortFlight = Object.entries(flight).toSorted()
  const lengthFlight: number = sortFlight.length
  const departureFlight: DictionariesFlight = sortFlight[0][1]
  const arrivalFlight: DictionariesFlight = sortFlight[lengthFlight - 1][1]
  return [
    {
      id: "1",
      value: "WEB",
    },
    {
      id: "2",
      value: "Ancillaries(AAA)",
    },
    {
      id: "3",
      value: `${card.country}`,
    },
    {
      id: "4",
      value: `${retrievesAnOrder.data?.id}`,
    },
    {
      id: "5",
      value: `${card.cardNumberOfinstallments}`,
    },
    {
      id: "6",
      value: "6",
    },
    {
      id: "8",
      value: "false",
    },
    {
      id: "9",
      value: `${retrievesAnOrder.data?.air.bounds[0].flights[0].fareFamilyCode}`,
    },
    {
      id: "10",
      value: "false",
    },
    {
      id: "21",
      value: `${card.documentType}`,
    },
    {
      id: "22",
      value: `${card.documentNumber}`,
    },
    {
      id: "23",
      value: "",
    },
    {
      id: "24",
      value: `${cleanNumber(card.cardNumber).substring(0, 6)}`,
    },
    {
      id: "26",
      value: `${card.phoneNumber}`,
    },
    {
      id: "33",
      value: cybersourceMddField33(retrievesAnOrder),
    },
    {
      id: "36",
      value: "False",
    },
    {
      id: "41",
      value: cybersourceMddField41(retrievesAnOrder),
    },
    {
      id: "42",
      value: cybersourceMddField42(retrievesAnOrder),
    },
    {
      id: "44",
      value: cybersourceMddField44(retrievesAnOrder),
    },
    {
      id: "45",
      value: cybersourceMddField45(retrievesAnOrder),
    },
    {
      id: "46",
      value: cybersourceMddField46And47(retrievesAnOrder, "departure"),
    },
    {
      id: "47",
      value: cybersourceMddField46And47(retrievesAnOrder, "arrival"),
    },
    {
      id: "48",
      value: getFormatDateCybersource(departureFlight.arrival.dateTime, true),
    },
    {
      id: "49",
      value: `${departureFlight.marketingAirlineCode}${departureFlight.marketingFlightNumber}`,
    },
    {
      id: "50",
      value: `${departureFlight.meals.bookingClass}`,
    },
    {
      id: "51",
      value: getFormatDateCybersource(arrivalFlight.departure.dateTime, true),
    },
    {
      id: "52",
      value: getFormatDateCybersource(arrivalFlight.arrival.dateTime, true),
    },
    {
      id: "53",
      value:
        lengthFlight > 1
          ? `${arrivalFlight.marketingAirlineCode}${arrivalFlight.marketingFlightNumber}`
          : "",
    },
    {
      id: "54",
      value: lengthFlight > 1 ? `${arrivalFlight.meals.bookingClass}` : "",
    },
    {
      id: "55",
      value: cybersourceMddField55(flight),
    },
    {
      id: "56",
      value: `${departureFlight.marketingAirlineCode}`,
    },
    {
      id: "76",
      value: `${retrievesAnOrder.data?.creationPointOfSale.pointOfSaleId}`,
    },
    {
      id: "77",
      value: getFormatDateCybersource(departureFlight.departure.dateTime, true),
    },
    {
      id: "81",
      value: "False",
    },
    {
      id: "82",
      value: "ES",
    },
  ]
}

export const handleOrderPayment = async (orderPayment: OrderPayment) => {
  const token: string = orderPayment.paymentTokens.tokenAv?.access_token!;

  const [year, month] = orderPayment.card.cardExpirationDate.split('/');
  const worldpayPaymentRequest: WorldpayPaymentRequest = {
    authenticatedShopperID: await getShopperID(),
    tokenEventReference: await getTokenReference(),
    tokenReason: await getChannelAVCOM(),
    cardNumber: orderPayment.card.cardNumber,
    expiryDateMonth: month,
    expiryDateYear: year,
    cardHolderName: "APPROVED",
    cvc: orderPayment.card.cardSecurityCode
  }

  const authorization = await postWorldpayAuthorization(worldpayPaymentRequest);
  const parser = new DOMParser();
  const xml = parser.parseFromString(authorization, 'application/xml');

  const authenticatedShopperID = xml.getElementsByTagName('authenticatedShopperID')[0].textContent;
  const paymentTokenID = xml.getElementsByTagName('paymentTokenID')[0].textContent;

  const tokenUS: TokenUS = {
    id: paymentTokenID,
    shopperId: authenticatedShopperID,
  }

  const countryCode = orderPayment.retrievesAnOrder.data?.creationPointOfSale.countryCode!;

  let franchise: string = "";
  const pos = countryCode === 'CO' ? countryCode : 'US'
  const paymentMethodsRequest: PaymentMethodsRequest = {
    canal: await getChannelPaymentMethod(),
    pais: pos,
    fechaVuelo: orderPayment.retrievesAnOrder.data?.creationDateTime
  };
  const responsePaymentMethods = await postPaymentMethods(paymentMethodsRequest);
  responsePaymentMethods?.map((item: any) => {
    item.franquicia?.map((res: any) => {
      if (res.nombre === orderPayment.card?.cardFranchise!) franchise = res.codigo
    });
  });

  const paymentRequest = viancaPayPaymentRequest(orderPayment, countryCode, franchise, tokenUS);
  console.log({ countryCode, rute: countryCode === 'CO' ? 'use Payment CO' : 'use Payment US', request: paymentRequest, orderPayment, worldpayPaymentRequest, token });
  if (countryCode === 'CO') {
    return await postViancaPayPayment(paymentRequest, token);
  } else {
    return await postViancaPayPaymentUs(paymentRequest, token);
  }
}

const viancaPayPaymentRequest = (orderPayment: OrderPayment, countryCode: string, franchise: string, tokenUS: TokenUS): ViancaPayPaymentRequest => {
  let grandTotalAmount = `${getGrandTotalAmount(orderPayment.retrievesAnOrder)}`
  let formatUSGrandTotalAmount = `${Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: 2 }).format(getGrandTotalAmount(orderPayment.retrievesAnOrder) / 100)}`
  return {
    language: orderPayment.paramsUrl.lang!,
    command: "SUBMIT_TRANSACTION",
    transaction: {
      order: {
        referenceCode: orderPayment.retrievesAnOrder.data?.id!,
        ...(countryCode === 'CO' ? { description: `MMBDAPI_${orderPayment.retrievesAnOrder.data?.id!}` } : { description: `${orderPayment.retrievesAnOrder.data?.id!}` }),
        language: orderPayment.paramsUrl.lang!,
        notifyUrl: null,
        additionalValues: {
          TX_VALUE: {
            ...(countryCode === 'CO' ? { value: grandTotalAmount } : { value: formatUSGrandTotalAmount }),
            currency: Object.entries(orderPayment.retrievesAnOrder.dictionaries?.currency!)[0][0],
            ...(countryCode === 'CO' ? {} : { exponent: null }),
          },
          TX_TAX: null,
          TX_TAX_RETURN_BASE: null,
          TX_ADDITIONAL_VALUE: null,
        },
        buyer: {
          merchantBuyerId: null,
          firstName: orderPayment.card.firstName,
          lastName: orderPayment.card.surname,
          ...(countryCode === 'CO' ? { fullName: `${orderPayment.card.firstName} ${orderPayment.card.surname}` } : {}),
          emailAddress: `${orderPayment.card.email}`,
          contactPhone: `${orderPayment.card.phoneNumber}`,
          dniNumber: `${orderPayment.card.documentNumber}`,
          shippingAddress: {
            street1: `${orderPayment.card.address}`,
            street2: "",
            city: `${orderPayment.card.city}`,
            state: "",
            country: `${orderPayment.card.country}`,
            postalCode: "",
            phone: `${orderPayment.card.phoneNumber}`,
          },
        },
        shippingAddress: null,
      },
      payer: {
        ...(countryCode === 'CO' ? { merchantPayerId: null } : { merchantPayerId: "" }),
        fullName: `${orderPayment.card.firstName} ${orderPayment.card.surname}`,
        emailAddress: `${orderPayment.card.email}`,
        contactPhone: `${orderPayment.card.phoneNumber}`,
        dniNumber: `${orderPayment.card.documentNumber}`,
        billingAddress: {
          street1: `${orderPayment.card.address}`,
          street2: "",
          city: `${orderPayment.card.city}`,
          ...(countryCode === 'CO' ? { state: '' } : { state: orderPayment.card?.state !== undefined ? orderPayment.card?.state : '' }),
          country: `${orderPayment.card.country}`,
          ...(countryCode === 'CO' ? { postalCode: '' } : { postalCode: orderPayment.card?.postalCode !== undefined ? orderPayment.card?.postalCode : '' }),
          phone: `${orderPayment.card.phoneNumber}`,
        },
      },
      ...(countryCode === 'CO' ? { creditCardTokenId: orderPayment.paymentTokens.tokenPayU?.creditCardToken?.creditCardTokenId! } : { creditCardTokenId: null }),
      cc: orderPayment.card.cardNumber,
      ...(countryCode === 'CO' ? { expirationDate: handleExpirationDateFormat(orderPayment.card.cardExpirationDate) } : { expirationDate: handleExpirationDateFormatUS(orderPayment.card.cardExpirationDate) }),
      ...(countryCode === 'CO' ? {} : { franchise }),
      ...(countryCode === 'CO' ? {} : {
        token: {
          id: tokenUS.id,
          shopperId: tokenUS.shopperId,
        }
      }),
      ...(countryCode === 'CO' ? {
        creditCard: {
          securityCode: orderPayment.card.cardSecurityCode,
          franchise: null,
          processWithoutCvv2: null,
        }
      } : {}),
      extraParameters: {
        INSTALLMENTS_NUMBER: Number(orderPayment.card.cardNumberOfinstallments),
        RESPONSE_URL: null,
        PSE_REFERENCE1: null,
        FINANCIAL_INSTITUTION_CODE: null,
        USER_TYPE: null,
        PSE_REFERENCE2: null,
        PSE_REFERENCE3: null,
        ...(countryCode === 'CO' ? { PSE_SERVICE_CODE: null } : {}),
      },
      type: "AUTHORIZATION_AND_CAPTURE",
      paymentMethod: orderPayment.card.cardFranchise,
      paymentCountry: orderPayment.card.country,
      deviceSessionId: orderPayment.paymentTokens.deviceSessionId,
      ipAddress: orderPayment.paymentTokens.ipAddress,
      cookie: getGuidCode(),
      userAgent: orderPayment.paymentTokens.userAgent,
      pnr: {
        id: `${orderPayment.retrievesAnOrder.data?.id}`,
        reservationOffice: {
          ...(countryCode === 'CO' ? { id: "BOGAV08LL" }
            : countryCode === 'MX' ? { id: "MEXAV08LL" }
              : { id: "SALTA08LL" }),
          country: `${orderPayment.retrievesAnOrder.data?.creationPointOfSale.countryCode}`,
        },
        saleOffice: {
          ...(countryCode === 'CO' ? { id: "BOGAV08LL" }
            : countryCode === 'MX' ? { id: "MEXAV08LL" }
              : { id: "SALTA08LL" }),
          country: countryCode,
        },
        passengers: getPassengers(orderPayment.retrievesAnOrder.data?.travelers!, orderPayment.card),
        itinerary: getItinerary(orderPayment.retrievesAnOrder.dictionaries?.flight!),
      },
      cybersource: {
        triptype: getTripType(orderPayment.retrievesAnOrder.dictionaries?.flight!),

        ...(countryCode === 'CO' ? {} : { thirdpartybooking: "0" }),
        ...(countryCode === 'CO' ? {} : { travelinsurance: "" }),
        ...(countryCode === 'CO' ? {} : { AdditionalCharges: "" }),
        ...(countryCode === 'CO' ? {} : { bank: "" }),
        ...(countryCode === 'CO' ? {} : { txduration: "" }),
        ...(countryCode === 'CO' ? {} : { CopyPasteTC: "false" }),
        ...(countryCode === 'CO' ? {} : { CopypasteNameTH: "false" }),
        ...(countryCode === 'CO' ? {} : { CopypasteBank: "" }),
        ...(countryCode === 'CO' ? {} : { CopypasteCVV: "false" }),
        ...(countryCode === 'CO' ? {} : { CopypasteEmail: "false" }),
        ...(countryCode === 'CO' ? {} : { from: "" }),
        ...(countryCode === 'CO' ? {} : { to: "" }),
        ...(countryCode === 'CO' ? {} : { Flexible: "" }),

        ...(countryCode === 'CO' ? { merchantID: "avianca_web" } : { merchantID: null }),
        ...(countryCode === 'CO' ? { merchantReferenceCode: getMerchantReferenceCode(orderPayment.retrievesAnOrder.data?.id!) } : { merchantReferenceCode: null }),
        ...(countryCode === 'CO' ? { ipAddress: orderPayment.paymentTokens.ipAddress } : { ipAddress: null }),
        ...(countryCode === 'CO' ? { departureDateTime: getDepartureDateTime(orderPayment.retrievesAnOrder.dictionaries?.flight!) } : { departureDateTime: null }),
        ...(countryCode === 'CO' ? { completeRoute: getCompleteRoute(orderPayment.retrievesAnOrder.dictionaries?.flight!) } : { completeRoute: null }),

        item: getItems(orderPayment),
        serviceItems: getServiceForId(orderPayment.retrievesAnOrder),
        purchaseTotals: {
          currency: Object.entries(orderPayment.retrievesAnOrder.dictionaries?.currency!)[0][0],
          ...(countryCode === 'CO' ? { grandTotalAmount: grandTotalAmount } : { grandTotalAmount: formatUSGrandTotalAmount }),
        },
        mddField: getMddField(orderPayment.retrievesAnOrder, orderPayment.card),
      },
    },
    canal: "SERVICING",
    country: countryCode,
    medio: "TC",
    lastname: orderPayment.paramsUrl.lastName!,
    ...(countryCode === 'CO' ? {} : { TimeTT: "false" })
  }
}